<template>
  <v-menu offset-y v-model="menuVisible" :open-on-hover="isOpenOnHover">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :class="getClassStyle()"
        :disabled="disabled"
        small
        :color="btnColor || 'primary'"
        :text="text"
        v-bind="attrs"
        v-on="on"
        :outlined="!mainAction">
        <v-icon small class="mr-1">{{ icon }}</v-icon>
        <span>{{ caption }}</span>
      </v-btn>
    </template>
    <div class="scrollable scrollbar">
      <v-list>
        <GatButtonMenuItem
          v-for="(menuItem, index) in menuItems"
          :menuItem="menuItem"
          :open-on-hover="false"
          :key="index"
          @click="menuItemClicked"
          @subMenuItemClick="subMenuItemClicked(menuItem, $event)" />
      </v-list>
    </div>
  </v-menu>
</template>

<script>
import GatButtonMenuItem from './GatButtonMenuItem.vue';
import './scrollbar.css';

export default {
  name: 'GatButtonMenu',
  props: {
    caption: String,
    disabled: Boolean,
    icon: String,
    mainAction: Boolean,
    menuItems: Array,
    openOnHover: Boolean,
    text: Boolean,
    btnColor: String,
    btnClass: String,
    useBottomPanelStyle: Boolean,
  },
  components: { GatButtonMenuItem },
  data() {
    return {
      menuVisible: false,
    };
  },

  created() {},

  watch: {},

  computed: {
    isOpenOnHover() {
      if (this.menuVisible === false && this.openOnHover) {
        return true;
      }
      return false;
    },
  },

  methods: {
    menuItemClicked(menuItem) {
      this.menuVisible = false;
      this.$emit('menuItemClick', menuItem);
    },

    subMenuItemClicked(rootMenuItem, subMenuItem) {
      this.menuVisible = false;
      this.$emit('subMenuItemClick', { root: rootMenuItem, item: subMenuItem });
    },

    getClassStyle() {
      if (this.btnClass) {
        return this.btnClass;
      }
      if (this.useBottomPanelStyle) {
        return 'ml-3 v-size--default';
      }
      return 'ml-1';
    },
  },
};
</script>

<style scoped>
.scrollable {
  max-height: 80vh;
  overflow-y: scroll;
  z-index: 201 !important;
}
</style>
