<template>
  <div class="pb-6 mb-6">
    <gat-dialog v-model="dialogVisible" :cancel-button-disabled="isSaving" cancelButtonCaption="CANCEL"
      okButtonCaption="OK" :fullscreen="isPhone" width="1000" :auto-close="false" @okClick="duplicateOkClicked(false)"
      @cancelClick="close" :okButtonDisabled="isSaving || !canCreatePortCall">
      <span v-if="title" class="text-h6 grey--text text--darken-1">
        {{ `${this.title} ${this.portcall.PORTCALL_NUMBER} (${this.originalVessel?.NAME ?? ''})` }}
        <v-progress-circular v-if="progress >= 0" :rotate="270" :value="(progress / 12) * 100"
          color="primary"></v-progress-circular>
        <v-icon v-if="progress === 12" size="auto" color="green">mdi-check-circle</v-icon>
        <span v-if="progress >= 0">Step: {{ progress }} of 12</span>
        <span v-if="progress >= 0 && progressMessage">{{ ` (${progressMessage})` }}</span>
      </span>
      <gat-form :title="this.title" v-model="isDuplicateValid" ref="form" :readonly="!canCreatePortCall"
        :is-loading="isSaving">
        <gat-sub-group size="md12 xs12">
          <gat-select size="md6 xs12" v-model="portcall.SETUP_ID" :items="agencyItems" label="Agency" :required="true" />
          <gat-vessel-select size="md6 xs12" v-model="portcall.VESSEL_ID" label="Vessel" :required="true" />
        </gat-sub-group>
        <gat-sub-group v-if="!fromTemplate" title="Mark the items that should be duplicated to the new port call"
          size="xs12 sm12">
          <GatEdit :disabled="!canDuplicateCargo" checkbox label="Cargo" v-model="chkCargo" size="xs12 sm6 md4" />
          <GatEdit :disabled="!canDuplicateChecklist" checkbox label="Checklist" v-model="chkCheclist"
            size="xs12 sm6 md4" />
          <GatEdit :disabled="!canDuplicateDynamicFields" checkbox label="Customer defined fields" v-model="chkDynaFields"
            size="xs12 sm6 md4" />
          <GatEdit :disabled="!canDuplicateExpenses" checkbox label="Expenses" v-model="chkExpenses"
            size="xs12 sm6 md4" />
          <GatEdit :disabled="!canDuplicateHotlist" checkbox label="Connected clients" v-model="chkHotlist"
            size="xs12 sm6 md4" />
          <GatEdit :disabled="!canDuplicateProforma" checkbox label="Proforma" v-model="chkProforma"
            size="xs12 sm6 md4" />
          <GatEdit :disabled="!canDuplicateProspects" checkbox label="Prospects" v-model="chkProspects"
            size="xs12 sm6 md4" />
          <GatEdit :disabled="!canDuplicateQuay" checkbox label="Berth & anchorage" v-model="chkQuay"
            size="xs12 sm6 md4" />
          <GatEdit :disabled="!canDuplicateRoute" checkbox label="Voyage" v-model="chkRoute" size="xs12 sm6 md4" />
          <GatEdit :disabled="!canDuplicateSof" checkbox label="Statement of facts" v-model="chkSOF"
            size="xs12 sm6 md4" />
          <GatEdit :disabled="!canDuplicateServices" checkbox label="Services" v-model="chkServices"
            size="xs12 sm6 md4" />
        </gat-sub-group>
        <gat-sub-group title="Port call number" size="xs12" v-if="showGenerateCallNumber">
          <GatEdit label="Generate port call number" v-model="chkGeneratePortCallNumber" toggle size="xs6" />
          <GatEdit v-show="!chkGeneratePortCallNumber" label="New port call number" v-model="portcall.PORTCALL_NUMBER"
            size="xs6" :maxChars="25" />
        </gat-sub-group>
      </gat-form>
      <v-alert v-if="this.errorMessage" color="orange darken-1" icon="mdi-flag" dense text outlined>
        <span class="font-weight-medium">{{ this.errorMessage }}</span><br />
      </v-alert>
      <gat-dialog v-model="duplicatePortcallWarningDialogVisible" cancelButtonCaption="NO" okButtonCaption="YES"
        :fullscreen="isPhone" width="1000" :auto-close="false" @okClick="duplicateOkClicked(true)"
        @cancelClick="userChooseToCancel" :okButtonDisabled="!canCreatePortCall">
        <gat-sub-group size="xs12"
          title="This port call number already exists! Do you still want to continue and create a non-unique port call number?" />
      </gat-dialog>
    </gat-dialog>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import moment from 'moment';
import { apiGet, apiPut, apiPost } from '@/store/api';
import { useApplicationStore } from '@/store/applicationStore';
import { usePortcallStore } from '@/store/portcallStore';
import { getBerthedPortcalls } from '@/services/api/api-calls/getBerthedPortcalls';
import { getPortcallDetails } from '@/services/api/api-calls/getPortcallDetails';
import GatVesselSelect from '../../app-components/GatVesselSelect.vue';
import SednaApiService from '../../services/api/external/sednaAPI';

export default {
  name: 'DuplicatePortCall',
  props: ['portcall', 'showForm', 'duplicateParams', 'fromTemplate'],
  components: {
    GatVesselSelect,
  },
  setup() {
    const portcallStore = usePortcallStore();
    const applicationStore = useApplicationStore();
    return {
      portcallStore,
      applicationStore,
    };
  },
  data() {
    return {
      progress: -1,
      isSaving: false,
      errorMessage: null,
      originalVessel: null,
      progressMessage: null,
      isDuplicateValid: false,
      dialogVisible: this.showForm,
      chkGeneratePortCallNumber: true,
      duplicatePortcallWarningDialogVisible: false,
      chkCargo: this.duplicateParams?.chkCargo?.toUpperCase() === 'CHECKED',
      chkCheclist: this.duplicateParams?.chkCheclist?.toUpperCase() === 'CHECKED',
      chkDynaFields: this.duplicateParams?.chkDynaFields?.toUpperCase() === 'CHECKED',
      chkExpenses: this.duplicateParams?.chkExpenses?.toUpperCase() === 'CHECKED',
      chkHotlist: this.duplicateParams?.chkHotlist?.toUpperCase() === 'CHECKED',
      chkProforma: this.duplicateParams?.chkProforma?.toUpperCase() === 'CHECKED',
      chkProspects: this.duplicateParams?.chkProspects?.toUpperCase() === 'CHECKED',
      chkQuay: this.duplicateParams?.chkQuay?.toUpperCase() === 'CHECKED',
      chkRoute: this.duplicateParams?.chkRoute?.toUpperCase() === 'CHECKED',
      chkSOF: this.duplicateParams?.chkSOF?.toUpperCase() === 'CHECKED',
      chkServices: this.duplicateParams?.chkServices?.toUpperCase() === 'CHECKED',
      title: this.fromTemplate ? 'Create new port call from template: ' : 'Duplicate port call: ',
    };
  },

  created() { },

  mounted() {
    this.progress = -1;
    this.isSaving = false;
    this.progressMessage = null;
    this.getVessel();
  },

  watch: {
    showForm(newValue) {
      this.dialogVisible = newValue;
    },
    chkCargo(newValue) {
      this.saveDuplicatePortcallParameters('chkCargo', newValue);
    },
    chkCheclist(newValue) {
      this.saveDuplicatePortcallParameters('chkCheclist', newValue);
    },
    chkDynaFields(newValue) {
      this.saveDuplicatePortcallParameters('chkDynaFields', newValue);
    },
    chkExpenses(newValue) {
      this.saveDuplicatePortcallParameters('chkExpenses', newValue);
    },
    chkHotlist(newValue) {
      this.saveDuplicatePortcallParameters('chkHotlist', newValue);
    },
    chkProforma(newValue) {
      this.saveDuplicatePortcallParameters('chkProforma', newValue);
    },
    chkProspects(newValue) {
      this.saveDuplicatePortcallParameters('chkProspects', newValue);
    },
    chkQuay(newValue) {
      this.saveDuplicatePortcallParameters('chkQuay', newValue);
    },
    chkRoute(newValue) {
      this.saveDuplicatePortcallParameters('chkRoute', newValue);
    },
    chkSOF(newValue) {
      this.saveDuplicatePortcallParameters('chkSOF', newValue);
    },
    chkServices(newValue) {
      this.saveDuplicatePortcallParameters('chkServices', newValue);
    },
  },

  computed: {
    isPhone() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    canCreatePortCall() {
      return !this.saving && this.applicationStore.userRight('P.NEW_AND_EDIT') && this.applicationStore.user.internal;
    },
    canDuplicateQuay() {
      return this.applicationStore.userRight('Q.NEW_AND_EDIT') && this.applicationStore.user.internal;
    },
    canDuplicateSof() {
      return this.applicationStore.userRight('SOF.NEW_AND_EDIT') && this.applicationStore.user.internal;
    },
    canDuplicateCargo() {
      return this.applicationStore.userRight('CA.NEW_AND_EDIT') && this.applicationStore.user.internal;
    },
    canDuplicateRoute() {
      return this.applicationStore.userRight('RO.NEW_AND_EDIT') && this.applicationStore.user.internal;
    },
    canDuplicateDynamicFields() {
      return this.applicationStore.userRight('UD.EDIT') && this.applicationStore.user.internal;
    },
    canDuplicateHotlist() {
      return this.applicationStore.userRight('H.EDIT') && this.applicationStore.user.internal;
    },
    canDuplicateChecklist() {
      return this.applicationStore.userRight('CH.NEW_AND_EDIT') && this.applicationStore.user.internal;
    },
    canDuplicateProforma() {
      return (
        (this.applicationStore.userRight('PR.NEW') || this.applicationStore.userRight('PR.NEW_FROM_PORTCALL')) &&
        this.applicationStore.user.internal
      );
    },
    canDuplicateExpenses() {
      return this.applicationStore.userRight('EX.NEW') && this.applicationStore.user.internal;
    },
    canDuplicateServices() {
      return this.applicationStore.userRight('SER.NEW_AND_EDIT') && this.applicationStore.user.internal;
    },
    canDuplicateProspects() {
      return this.applicationStore.userRight('PRO.NEW') && this.applicationStore.user.internal;
    },
    showGenerateCallNumber() {
      return this.applicationStore.globalSettings.SGL_PC_HIDE_GENERATE_PCNO_NEW_PC !== 1;
    },

    agencyItems() {
      const agency = this.applicationStore.agencySettings;
      const result = agency.map((obj) => {
        const newObj = {};
        if (obj.ID) {
          newObj.text = obj.GROUP_NAME;
          newObj.value = obj.ID;
        }
        return newObj;
      });
      return result;
    },
  },

  methods: {
    checkResult(apiCallResult) {
      if (apiCallResult) {
        if (apiCallResult.resultCategory > 2) {
          this.errorMessage = apiCallResult.message;
          this.isSaving = false;
          return false;
        }
      }
      return true;
    },

    userChooseToCancel() {
      this.duplicatePortcallWarningDialogVisible = false;
      this.saving = false;
    },

    async getVessel() {
      apiGet(`helper/vessel/${this.portcall.VESSEL_ID}`).then((data) => {
        this.originalVessel = data.length > 0 ? data[0] : null;
      });
    },

    async saveDuplicatePortcallParameters(key, value) {
      this.isSaving = true;
      await apiPut('portcall/duplicate-portcall-parameters', { KEY_TEXT: key, VALUE_TEXT: value });
      this.isSaving = false;
    },

    async duplicateOkClicked(userChoice) {
      try {
        this.errorMessage = null;
        this.duplicatePortcallWarningDialogVisible = false;
        this.$refs.form.validate();
        if (this.isDuplicateValid) {
          this.isSaving = true;
          let validPortcallNum = this.chkGeneratePortCallNumber || userChoice;
          const { SETUP_ID, VESSEL_ID, quayId, PORTCALL_NUMBER } = this.portcall;
          if (!validPortcallNum) {
            const portCallResult = await apiPost('portcall/duplicatepc/check', { SETUP_ID, PORTCALL_NUMBER });
            const { REQUIRES_UNIQE_PORTCALL_NO, EXISTING_PORTCALL_NO } = portCallResult.payload;
            validPortcallNum = !EXISTING_PORTCALL_NO;
            if (EXISTING_PORTCALL_NO) {
              if (REQUIRES_UNIQE_PORTCALL_NO) {
                this.errorMessage = 'Portcall number already in use. Must be unique.';
                this.isSaving = false;
                return;
              }
              this.isSaving = false;
              this.duplicatePortcallWarningDialogVisible = true;
              return;
            }
          }

          if (validPortcallNum) {
            this.progress = 1;
            this.progressMessage = this.fromTemplate ? 'Creating new port call from template' : 'Duplicating port call';
            const isoDate = moment(new Date()).format('yyyy-MM-DDTHH:mm:ss');

            const portCallResult = await apiPut('portcall/duplicatepc', {
              ID: this.portcall.ID,
              SETUP_ID, // agency
              VESSEL_ID,
              QUAY_ID: quayId,
              PORTCALL_NUMBER,
              HISTORY_TIME: isoDate,
              chkGeneratePortCallNumber: this.chkGeneratePortCallNumber,
            });
            if (!this.checkResult(portCallResult)) return;
            const newId = portCallResult.payload;

            const sednaApi = new SednaApiService(SETUP_ID);
            if (sednaApi.isSednaIntegration) {
              const sednaJobId = await sednaApi.createSednaJobRef(newId, 'portcall');
              if (sednaJobId) {
                this.portcallStore.updateSednaJobId({ id: this.portcall.ID, sednaJobId });
              }
            }

            const commonParams = {
              SETUP_ID, // agency
              NEW_ID: newId,
              HISTORY_TIME: isoDate,
              OLD_ID: this.portcall.ID,
            };

            let docksLookup = null;
            let sofsLookup = null;
            let cargosLookup = null;
            let expensesLookup = null;

            this.progress += 1;
            if (
              this.fromTemplate ||
              (this.canDuplicateQuay && this.chkQuay) ||
              (this.canDuplicateCargo && this.chkCargo)
            ) {
              this.progressMessage = 'Duplicating quays';
              const quayResult = await apiPut('portcall/duplicatepc/params', { ...commonParams, chkQuay: true });
              if (!this.checkResult(quayResult)) return;
              docksLookup = quayResult.payload;
            }
            this.progress += 1;
            if (this.fromTemplate || (this.canDuplicateSof && this.chkSOF)) {
              this.progressMessage = 'Duplicating sofs';
              const sofResult = await apiPut('portcall/duplicatepc/params', {
                ...commonParams,
                DOCKS_LOOKUP: docksLookup,
                chkSOF: true,
              });
              if (!this.checkResult(sofResult)) return;
              sofsLookup = sofResult.payload;
            }
            this.progress += 1;
            if (this.fromTemplate || (this.canDuplicateCargo && this.chkCargo)) {
              this.progressMessage = 'Duplicating cargos';
              const cargoResult = await apiPut('portcall/duplicatepc/params', {
                ...commonParams,
                DOCKS_LOOKUP: docksLookup,
                SOFS_LOOKUP: sofsLookup,
                chkCargo: true,
              });
              if (!this.checkResult(cargoResult)) return;
              cargosLookup = cargoResult.payload;
            }
            this.progress += 1;
            if (this.fromTemplate || (this.canDuplicateRoute && this.chkRoute)) {
              this.progressMessage = 'Duplicating voyages';
              const routeResult = await apiPut('portcall/duplicatepc/params', { ...commonParams, chkRoute: true });
              if (!this.checkResult(routeResult)) return;
            }
            this.progress += 1;
            if (this.fromTemplate || (this.canDuplicateDynamicFields && this.chkDynaFields)) {
              this.progressMessage = 'Duplicating user defined fields';
              const dynaResult = await apiPut('portcall/duplicatepc/params', { ...commonParams, chkDynaFields: true });
              if (!this.checkResult(dynaResult)) return;
            }
            this.progress += 1;
            if (this.fromTemplate || (this.canDuplicateHotlist && this.chkHotlist)) {
              // CONNECTED CLIENTS
              this.progressMessage = 'Duplicating connected clients';
              const hotlistResult = await apiPut('portcall/duplicatepc/params', { ...commonParams, chkHotlist: true });
              if (!this.checkResult(hotlistResult)) return;
            }
            this.progress += 1;
            if (this.fromTemplate || (this.canDuplicateChecklist && this.chkCheclist)) {
              this.progressMessage = 'Duplicating checklists';
              const checklistResult = await apiPut('portcall/duplicatepc/params', {
                ...commonParams,
                chkCheclist: true,
              });
              if (!this.checkResult(checklistResult)) return;
            }
            this.progress += 1;
            if (this.fromTemplate || (this.canDuplicateProforma && this.chkProforma)) {
              this.progressMessage = 'Duplicating proformas';
              const proformaResult = await apiPut('portcall/duplicatepc/params', {
                ...commonParams,
                chkProforma: true,
              });
              if (!this.checkResult(proformaResult)) return;
            }
            this.progress += 1;
            if (this.fromTemplate || (this.canDuplicateExpenses && this.chkExpenses)) {
              this.progressMessage = 'Duplicating expenses';
              const expenseResult = await apiPut('portcall/duplicatepc/params', { ...commonParams, chkExpenses: true });
              if (!this.checkResult(expenseResult)) return;
              expensesLookup = expenseResult.payload;
            }
            this.progress += 1;
            if (this.fromTemplate || (this.canDuplicateServices && this.chkServices)) {
              this.progressMessage = 'Duplicating services';
              const servicesResult = await apiPut('portcall/duplicatepc/params', {
                ...commonParams,
                EXPENSES_LOOKUP: expensesLookup,
                chkServices: true,
              });
              if (!this.checkResult(servicesResult)) return;
            }
            this.progress += 1;
            if (this.fromTemplate || (this.canDuplicateProspects && this.chkProspects)) {
              this.progressMessage = 'Duplicating prospects';
              const prospectResult = await apiPut('portcall/duplicatepc/params', {
                ...commonParams,
                CARGOS_LOOKUP: cargosLookup,
                chkProspects: true,
              });
              if (!this.checkResult(prospectResult)) return;
            }

            // Find port call promise result
            this.isSaving = false;
            this.close();
            getBerthedPortcalls(); // Update attendance berthed portcalls select input.
            // Update store with new port call details...
            this.portcallStore.clearCall();
            getPortcallDetails(newId);
            this.applicationStore.setWhiteBoardDataIsDirty(true);
            if (portCallResult.resultCategory === 1 && newId) {
              this.progress = -1;
              this.$router.push(`/call/${newId}`);
            }
          } else {
            this.isSaving = false;
            this.errorMessage = 'Portcall number already in use. Must be unique.';
          }
        } else {
          this.close();
        }
      } catch (err) {
        this.isSaving = false;
        this.errorMessage = err;
      }
    },

    close() {
      this.isSaving = false;
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
.ais {
  width: calc(100% - 250px);
}

@media only screen and (max-width: 600px) {
  .ais {
    width: 100%;
  }
}
</style>
