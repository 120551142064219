<template>
  <GatGroup class="pa-0" :title="caption" :collapsed="!expandAllGroups">
    <!-- <v-btn color="primary" dark  outlined small :disabled="selectedKeys.length==0 || !applicationStore.userRight('SOF.EDIT') || !applicationStore.userRight('SOF.NEW_AND_EDIT')" slot="btn"  @click="deleteClicked">
          <v-icon>delete</v-icon>Delete
        </v-btn> -->
    <gat-grid
      ref="sofGrid"
      :items="lines"
      :columns="columns"
      class="elevation-0"
      :noPadding="true"
      keyName="ID"
      dense
      :tight="true">
      <!-- cols-class="inlineEditCol" :selectable="true" @selectionChanged="onSelectionChanged"  -->
      <template slot="cell" slot-scope="props">
        <gat-edit
          class="pa-0"
          v-if="props.column.field == 'SORT_ORDER'"
          v-model="props.row.SORT_ORDER"
          dataType="number"
          :noflex="true"
          @input="editChanged"
          :readonly="readOnly"
          :disabled="disabled" />
        <v-combobox
          v-else-if="
            props.row.DETAIL_TYPE == 1 &&
            props.column.field == 'STATEMENT' &&
            applicationStore.globalSettings.SGL_WORKLINE_DISABLE_STATEMENT_USER_INPUT !== 1
          "
          v-model="props.row.STATEMENT"
          :items="getWorklineStatements(props.row)"
          :dense="gatComponentsStore.input_dense"
          :return-object="false"
          outlined
          @input="editChanged"
          :readonly="readOnly"
          :disabled="disabled" />
        <v-autocomplete
          v-else-if="
            props.row.DETAIL_TYPE === 1 &&
            props.column.field === 'STATEMENT' &&
            applicationStore.globalSettings.SGL_WORKLINE_DISABLE_STATEMENT_USER_INPUT === 1
          "
          class="custom-placeholer-color"
          v-model="props.row.STATEMENT"
          :items="getWorklineStatements(props.row)"
          :dense="gatComponentsStore.input_dense"
          :return-object="false"
          persistent-placeholder
          clearable
          outlined
          @input="editChanged"
          :readonly="readOnly"
          :disabled="disabled" />
        <!-- :placeholder="props.row.STATEMENT" -->
        <gat-edit
          v-else-if="props.column.field == 'STATEMENT'"
          v-model="props.row.STATEMENT"
          :noflex="true"
          @input="editChanged"
          :maxChars="200"
          value="test"
          :readonly="readOnly"
          :disabled="disabled" />
        <gat-edit
          v-else-if="props.column.field == 'FROM_DATE'"
          v-model="props.row.FROM_DATE"
          dateEdit
          :noflex="true"
          @input="editChanged(lineType, props.row, 'FROM_DATE')"
          :readonly="readOnly"
          :disabled="disabled"
          :errorMessages="lineType === 'workLines' ? getErrorMsg(props.row.ID, 'VALIDATE_WORKLINE', true) : ''" />
        <div v-else-if="props.column.field == 'FROM_TIME'" class="d-flex">
          <gat-edit
            v-model="props.row.FROM_TIME"
            :timeEdit="true"
            :noflex="true"
            @input="editChanged(lineType, props.row)"
            :readonly="readOnly"
            :disabled="disabled"
            :errorMessages="lineType === 'workLines' ? getErrorMsg(props.row.ID, 'VALIDATE_WORKLINE', true) : ''">
            <template slot="append">
              <div v-if="sofIsNotValid(props.row.ID, 'FROM_DATE')" @click.stop class="d-flex mt-1">
                <v-tooltip :bottom="true" open-on-click color="primary" class="tooltip-test">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" small v-bind="attrs" v-on="on">mdi-alert</v-icon>
                  </template>
                  <div>
                    {{ getErrorMsg(props.row.ID, 'FROM_DATE') }}
                  </div>
                </v-tooltip>
              </div>
            </template>
          </gat-edit>
          <!-- <div v-if="sofIsNotValid(props.row.ID)">TEST</div> -->
        </div>
        <gat-edit
          v-else-if="props.column.field == 'TO_DATE'"
          v-model="props.row.TO_DATE"
          dateEdit
          :noflex="true"
          @input="editChanged(lineType, props.row, 'TO_DATE')"
          :readonly="readOnly"
          :disabled="disabled"
          :errorMessages="lineType === 'workLines' ? getErrorMsg(props.row.ID, 'VALIDATE_WORKLINE', true) : ''" />
        <!-- :errorMessages="validateSofWorklineDate(props.row.ID)" -->
        <gat-edit
          v-else-if="props.column.field == 'TO_TIME'"
          v-model="props.row.TO_TIME"
          :timeEdit="true"
          :noflex="true"
          @input="editChanged(lineType, props.row)"
          :readonly="readOnly"
          :disabled="disabled"
          :errorMessages="lineType === 'workLines' ? getErrorMsg(props.row.ID, 'VALIDATE_WORKLINE', true) : ''">
          <!-- :errorMessages="validateSofWorklineDate(props.row.ID)" -->
          <template slot="append">
            <div v-if="sofIsNotValid(props.row.ID, 'TO_DATE')" @click.stop class="d-flex mt-1">
              <v-tooltip :bottom="true" open-on-click color="primary" class="tooltip-test">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="primary" small v-bind="attrs" v-on="on">mdi-alert</v-icon>
                </template>
                <div>
                  {{ getErrorMsg(props.row.ID, 'TO_DATE') }}
                </div>
              </v-tooltip>
            </div>
          </template>
        </gat-edit>
        <div v-else-if="props.column.field == 'COMMENTS'" class="d-flex">
          <gat-edit
            v-model="props.row.COMMENTS"
            :noflex="true"
            @input="editChanged"
            :maxChars="4000"
            style="width: 100%"
            :readonly="readOnly"
            :disabled="disabled" />
          <!-- <v-btn v-if="!$vuetify.breakpoint.xs" :disabled="!props.row.ID" icon @click="deleteItem(props.row.ID)"><v-icon>delete</v-icon></v-btn> -->
        </div>
        <gat-edit
          v-else-if="props.column.field == 'QUANTITY'"
          v-model="props.row.QUANTITY"
          dataType="number"
          :noflex="true"
          @input="editChanged"
          :readonly="readOnly"
          :disabled="disabled" />
        <gat-select
          v-else-if="props.column.field == 'GOODS_ID'"
          v-model="props.row.GOODS_ID"
          :items="cargoes"
          :noflex="true"
          @input="editChanged"
          :readonly="readOnly"
          :disabled="disabled"
          codeFieldName="ID"
          textFieldName="TEXT">
        </gat-select>

        <gat-edit
          v-else-if="props.column.field == 'HOLD'"
          v-model="props.row.HOLD"
          :noflex="true"
          @input="editChanged"
          :maxChars="100"
          :readonly="readOnly"
          :disabled="disabled" />
        <gat-edit
          v-else-if="props.column.field == 'GANGS'"
          v-model="props.row.GANGS"
          dataType="number"
          :decimals="0"
          :noflex="true"
          @input="editChanged"
          :readonly="readOnly"
          :disabled="disabled" />
        <gat-select
          v-else-if="props.column.field == 'REQ_BEFORE'"
          v-model="props.row.REQ_BEFORE"
          :items="requiredTypes"
          textFieldName="NAME"
          codeFieldName="ID"
          :noflex="true"
          @input="editChanged"
          :readonly="readOnly"
          :disabled="disabled">
        </gat-select>
        <gat-select
          v-else-if="props.column.field == 'DETAIL_STATUS'"
          v-model="props.row.DETAIL_STATUS"
          :items="worklineStatuses"
          :noflex="true"
          @input="editChanged"
          :readonly="readOnly"
          :disabled="disabled" />
        <gat-select
          v-else-if="props.column.field == 'QUAY_ID'"
          v-model="props.row.QUAY_ID"
          :items="berths"
          :noflex="true"
          @input="editChanged"
          :readonly="readOnly"
          :disabled="disabled">
        </gat-select>
        <div v-else-if="props.column.field == 'USER_ACTION'" class="user-action">
          <v-btn :disabled="readOnly || !props.row.ID" icon @click="deleteItem(props.row.ID)"
            ><v-icon>delete</v-icon></v-btn
          >
        </div>
        <div v-if="getErrorMsg(props.row.ID, 'VALIDATE_WORKLINE')" class="d-flex-column" style="position: relative">
          <div class="errorLine" style="background: none" />
          <div
            v-if="props.column.field == 'FROM_DATE'"
            :style="'width:' + getGridWidth + 'px;'"
            class="sof-error-message d-flex align-center">
            {{ getErrorMsg(props.row.ID, 'VALIDATE_WORKLINE', true) }}
          </div>
        </div>
      </template>
    </gat-grid>
    <template slot="compact">
      <v-simple-table dense>
        <template v-slot:default>
          <tbody class="sof-compact-grid">
            <th v-if="lineType != 'workLines' && hasPropertyValue(lines, 'SORT_ORDER')" class="text-left">No</th>
            <th v-if="lineType != 'workLines' && hasPropertyValue(lines, 'STATEMENT')" class="text-left">Statement</th>
            <th v-if="lineType != 'workLines' && hasPropertyValue(lines, 'FROM_TIME')" class="text-left">
              Date / time
            </th>
            <th v-if="lineType != 'workLines' && hasPropertyValue(lines, 'COMMENTS')" class="text-left">Comments</th>

            <th v-if="lineType == 'workLines' && hasPropertyValue(lines, 'SORT_ORDER')" class="text-left">Sort</th>
            <th
              v-if="
                lineType == 'workLines' && (hasPropertyValue(lines, 'TO_DATE') || hasPropertyValue(lines, 'FROM_DATE'))
              "
              class="text-left">
              Date (From - To)
            </th>
            <th v-if="lineType == 'workLines' && hasPropertyValue(lines, 'DETAIL_STATUS')" class="text-left">Status</th>
            <th v-if="lineType == 'workLines' && hasPropertyValue(lines, 'STATEMENT')" class="text-left">Statement</th>
            <th v-if="lineType == 'workLines' && hasPropertyValue(lines, 'QUANTITY')" class="text-left">Quantity</th>
            <th v-if="lineType == 'workLines' && hasPropertyValue(lines, 'GOODS_ID')" class="text-left">Cargo</th>

            <th v-if="lineType == 'workLines' && hasPropertyValue(lines, 'HOLD')" class="text-left">Hold</th>
            <th v-if="lineType == 'workLines' && hasPropertyValue(lines, 'GANGS')" class="text-left">Gangs</th>
            <th v-if="lineType == 'workLines' && hasPropertyValue(lines, 'QUAY_ID')" class="text-left">Quay</th>
            <th v-if="lineType == 'workLines' && hasPropertyValue(lines, 'COMMENTS')" class="text-left">Comments</th>
            <tr v-show="item.FROM_TIME || item.FROM_DATE" v-for="(item, index) in lines" :key="index">
              <!-- Sort order -->
              <td>{{ item.SORT_ORDER }}</td>
              <!-- Statement -->
              <td v-if="lineType != 'workLines'">{{ item.STATEMENT }}</td>
              <!-- Date, from time, to time -->
              <td class="pl-0" nowrap>
                {{ globalFormatDate(item.FROM_DATE, defaultGlobalShortDateFormat) }}
                <span
                  >{{ globalFormatTime(item.FROM_TIME) }}
                  <span v-show="lineType == 'workLines'"> - {{ globalFormatTime(item.TO_TIME) }}</span>
                  <span v-show="lineType == 'workLines' && item.TO_DATE && item.TO_DATE !== item.FROM_DATE">
                    ({{ globalFormatDate(item.TO_DATE, defaultGlobalShortDateFormat) }})</span
                  >
                </span>
              </td>
              <td v-if="lineType == 'workLines'">{{ getWorklineStatus(item.DETAIL_STATUS) }}</td>
              <td v-if="lineType == 'workLines'">{{ item.STATEMENT }}</td>
              <td v-if="lineType == 'workLines'">{{ item.QUANTITY }}</td>
              <td v-if="lineType == 'workLines'">{{ getCargoTextById(item.GOODS_ID) }}</td>

              <td v-if="lineType == 'workLines'">{{ item.HOLD }}</td>
              <td v-if="lineType == 'workLines'">{{ item.GANGS }}</td>
              <td v-if="lineType == 'workLines'">{{ getQuayById(item.QUAY_ID) }}</td>
              <td>
                <i> {{ item.COMMENTS }}</i>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      <!-- <record-presenter :value="lines"/> -->
    </template>
    <v-checkbox
      v-if="lineType === 'workLines'"
      v-model="sortAutomaticallyValue"
      true-value="Checked"
      false-value="Unchecked"
      label="Insert sort order automatically"
      class="d-flex ml-auto"
      style="width: 250px" />

    <!-- <record-presenter :value="lines"/> -->
  </GatGroup>
</template>

<script>
import GatUtils from '@/app-components/GatUtils';
import { useConstantsStore } from '@/store/constantsStore';
import { useApplicationStore } from '@/store/applicationStore';
import { usePortcallStore } from '@/store/portcallStore';
import { useHelperStore } from '@/store/helperStore';
import { useGatComponentsStore } from '@/store/gatComponentsStore';
import { GlobalHelperFunctions } from '@/common/GlobalHelperFunctions';

export default {
  name: 'SofLineEditGrid',
  props: {
    value: [Object, Array],
    lineType: String,
    berths: [Object, Array],
    caption: String,
    sofId: [Number, String],
    readOnly: Boolean,
    disabled: Boolean,
    dataLoaded: Boolean,
    cargoes: [Object, Array],
    sortAutomatically: String,
  },
  components: {
    /* RecordPresenter */
  },
  setup() {
    const constantsStore = useConstantsStore();
    const applicationStore = useApplicationStore();
    const portcallStore = usePortcallStore();
    const helperStore = useHelperStore();
    const gatComponentsStore = useGatComponentsStore();
    return {
      gatComponentsStore,
      helperStore,
      portcallStore,
      applicationStore,
      constantsStore,
    };
  },
  data() {
    return {
      lines: [],
      linesCount: 0,
      lineErrorIds: [], // stores an array of error objects for SOF-line validation.
      gridColumns: [],
      sofIsValidated: false,
      // selectedKeys:[],
      expandAllGroups: true,
      requiredTypes: [
        { NAME: 'No', ID: 0 },
        { NAME: 'Before Anchored, Berthed, Sailed and Archived', ID: 1 },
        { NAME: 'Before Berthed, Sailed and Archived', ID: 2 },
        { NAME: 'Before Sailed and Archived', ID: 3 },
        { NAME: 'Before Archived', ID: 4 },
      ],
      sortAutomaticallyValue: undefined,
    };
  },

  created() {
    if (this.value) {
      this.lines = GatUtils.makeCopyViaJson(this.value);
      if (!this.lastItemIsEmptyObject(this.lines)) {
        this.lines.push({});
      }
    }

    window.messageBus.$on('sof-was-added', () => {
      if (this.expandAllGroups) {
        // force update
        this.expandAllGroups = false;
        this.$nextTick(() => {
          this.expandAllGroups = true;
        });
      } else {
        this.expandAllGroups = true;
      }
    });
  },

  computed: {
    defaultGlobalShortDateFormat() {
      return GlobalHelperFunctions.defaultGlobalShortDateFormat;
    },
    saveIsDisabled() {
      let result = false;
      if (this.lineErrorIds.length > 0) {
        this.lineErrorIds.forEach((item) => {
          if (item.ERROR_MSG) {
            result = true;
          }
        });
      }
      return result;
    },
    portcallATA() {
      const ataDate = this.portcallStore.callDetails.ATA_DATE || null;
      const ataTime = this.portcallStore.callDetails.ATA_TIME || null;
      return this.combineFormattedDateAndTime(ataDate, ataTime);
    },
    portcallETA() {
      const etaDate = this.portcallStore.callDetails.ETA_DATE || null;
      const etaTime = this.portcallStore.callDetails.ETA_TIME || null;
      return this.combineFormattedDateAndTime(etaDate, etaTime);
    },
    portCallETPS() {
      const etpsDate = this.portcallStore.callDetails.extra.PCE_ETPS_DATE || null;
      const etpsTime = this.portcallStore.callDetails.extra.PCE_ETPS_TIME || null;
      return this.combineFormattedDateAndTime(etpsDate, etpsTime, true);
    },
    portcallATD() {
      const ataDate = this.portcallStore.callDetails.ATD_DATE || null;
      const ataTime = this.portcallStore.callDetails.ATD_TIME || null;
      return this.combineFormattedDateAndTime(ataDate, ataTime);
    },
    portcallETD() {
      const etdDate = this.portcallStore.callDetails.ETD_DATE || null;
      const etdTime = this.portcallStore.callDetails.ETD_TIME || null;
      return this.combineFormattedDateAndTime(etdDate, etdTime);
    },
    arrival() {
      if (this.lineType != 'workLines' && this.portCallETPS) {
        return {
          DATE_TIME: this.portCallETPS,
          TYPE: 'estimated pilot station (ETPS)',
        };
      }
      if (this.portcallATA) {
        return {
          DATE_TIME: this.portcallATA,
          TYPE: 'actual time of arrival (ATA)',
        };
      }
      if (this.portcallETA) {
        return {
          DATE_TIME: this.portcallETA,
          TYPE: 'estimated time of arrival (ETA)',
        };
      }
      return {
        DATE_TIME: null,
        TYPE: null,
      };
    },
    departure() {
      if (this.portcallATD) {
        return {
          DATE_TIME: this.portcallATD,
          TYPE: 'actual time of departure (ATD)',
        };
      }
      if (this.portcallETD) {
        return {
          DATE_TIME: this.portcallETD,
          TYPE: 'estimated time of departure (ETD)',
        };
      }
      return {
        DATE_TIME: null,
        TYPE: null,
      };
    },
    columns() {
      const result = [];
      if (this.lineType == 'workLines') {
        result.push({ header: 'Sort', field: 'SORT_ORDER', hideOnPhone: false, width: '52px' });
        result.push({ header: 'From date', field: 'FROM_DATE', hideOnPhone: false, dataType: 'date', width: '160px' });
        result.push({
          header: 'Time',
          field: 'FROM_TIME',
          hideOnPhone: false,
          dataType: 'time',
          minWidth: '90px',
          width: '80px',
        });
        result.push({ header: 'To date', field: 'TO_DATE', hideOnPhone: false, dataType: 'date', width: '160px' });
        result.push({
          header: 'To time',
          field: 'TO_TIME',
          hideOnPhone: false,
          dataType: 'time',
          minWidth: '90px',
          width: '80px',
        });
        result.push({
          header: 'Status',
          field: 'DETAIL_STATUS',
          hideOnPhone: false,
          minWidth: '120px',
          width: '120px',
        });
        result.push({ header: 'Statement', field: 'STATEMENT', hideOnPhone: false, minWidth: '200px' });
        result.push({ header: 'Quantity', field: 'QUANTITY', hideOnPhone: false, width: '40px' });
        result.push({ header: 'Cargo', field: 'GOODS_ID', hideOnPhone: false, width: '200px' });

        result.push({ header: 'Hold', field: 'HOLD', hideOnPhone: false, width: '70px' });
        result.push({ header: 'Gangs', field: 'GANGS', hideOnPhone: false, width: '70px' });
        result.push({ header: 'Quay', field: 'QUAY_ID', hideOnPhone: false, minWidth: '100px' });
        result.push({ header: 'Comments', field: 'COMMENTS', hideOnPhone: false });

        result.push({ header: '', field: 'USER_ACTION', width: '10px', sortable: false });
      } else {
        result.push({ header: 'No', field: 'SORT_ORDER', hideOnPhone: false, width: '52px' });
        result.push({ header: 'Statement', field: 'STATEMENT', hideOnPhone: false });
        result.push({
          header: 'Date',
          field: 'FROM_DATE',
          hideOnPhone: false,
          dataType: 'date',
          width: '175px',
          minWidth: '175px',
        });
        result.push({
          header: 'Time',
          field: 'FROM_TIME',
          hideOnPhone: false,
          dataType: 'time',
          minWidth: '90px',
          width: '80px',
        });
        // result.push({"header":"Doc code","field":"SUBST_CODE",hideOnPhone:true});
        result.push({ header: 'Comment', field: 'COMMENTS', hideOnPhone: false });
        // result.push({"header":"Required","field":"REQ_BEFORE",hideOnPhone:true});
        // result.push({"header":"Connected to","field":"PORTCALL_FIELD",hideOnPhone:true});
        // result.push({"header":"Port call status",calculated: (rowData)=>{
        // if(rowData.PORTCALL_STATUS == -1) {return ''}
        // else return this.lookupCallStatus(rowData.PORTCALL_STATUS)
        // },hideOnPhone:true});
        // result.push({"header":"Code","field":"CODE1",hideOnPhone:true});
        /* if(this.$vuetify.breakpoint.xs){
                      result.push({"header":"No","field":"SORT_ORDER",hideOnPhone:false,width:"40px"});
                  } */
        result.push({ header: '', field: 'USER_ACTION', width: '10px', sortable: false });
      }
      return result;
    },

    worklineStatuses() {
      return this.constantsStore.worklineStatuses;
    },

    getGridWidth() {
      let width = this.$refs.sofGrid.$el.clientWidth;
      if (!this.$vuetify.breakpoint.xs) {
        // account for removal of sort order colum on mobile.
        width -= 54;
      }
      return width;
    },
  },

  watch: {
    sortAutomatically: {
      immediate: true,
      handler(val) {
        this.sortAutomaticallyValue = val;
      },
    },
    sortAutomaticallyValue(val) {
      this.$emit('update:sortAutomatically', val);
    },
    saveIsDisabled(newVal) {
      this.$emit('saveDisabled', newVal);
    },
    value(newValue) {
      this.lines = GatUtils.makeCopyViaJson(newValue);
      if (newValue && !this.lastItemIsEmptyObject(this.lines)) {
        this.lines.push({});
      }
    },
    lines(newVal) {
      if (this.linesCount != 0 && this.linesCount < newVal.length) {
        // check if the lineCount has increased
        // validate new line
        this.$nextTick(() => {
          this.validateArrivalAndDeparture(this.lineType, newVal[newVal.length - 2]);
        });
      }
      this.linesCount = newVal.length;
    },
    arrival() {
      // watch for changes on the portcall arrival && re-validate sof-lines.
      this.sofIsValidated = false;
    },
    departure() {
      // watch for changes on the portcall departure && re-validate sof-lines.
      this.sofIsValidated = false;
    },
    sofId: {
      immediate: true,
      handler() {
        this.$nextTick(() => {
          this.sofIsValidated = false;
        });
      },
    },
    sofIsValidated: {
      immediate: true,
      handler(newVal) {
        if (!newVal) {
          this.validateAllSofLines();
        }
      },
    },
    dataLoaded: {
      immediate: true,
      handler(loaded) {
        if (loaded === true) {
          this.validateAllSofLines();
          this.$emit('loadingFinished');
        }
      },
    },
  },

  methods: {
    globalFormatTime(time, format) {
      return GlobalHelperFunctions.globalFormatTime(time, format);
    },
    globalFormatDate(date, format) {
      return GlobalHelperFunctions.globalFormatDate(date, format);
    },
    getCargoTextById(id) {
      const cargo = this.cargoes.find((item) => item.ID === id);
      if (cargo && cargo.TEXT) {
        return cargo.TEXT;
      }
      return '';
    },
    validateAllSofLines() {
      /* this.$nextTick(()=>{
                  this.lines.forEach(item =>{
                      this.validateArrivalAndDeparture(this.lineType, item);
                  })
              },1000) */

      this.lineErrorIds = [];
      this.lines.forEach((item) => {
        this.validateArrivalAndDeparture(this.lineType, item);
        this.validateSofWorklineDate(item.ID);
      });
      this.sofIsValidated = true;
    },
    // Aggregate if-statement for making the red line under a sof item invisible.
    errorLineIsInvisible(fieldName, rowItem) {
      if (fieldName === 'USER_ACTION') {
        // || fieldName === 'FROM_DATE' || fieldName === 'FROM_TIME' || fieldName === 'TO_DATE' || fieldName === 'TO_TIME'
        return false;
      }
      if (fieldName === 'FROM_DATE' || fieldName === 'FROM_TIME') {
        const found = this.lineErrorIds.find((item) => item.ID === rowItem.ID);
        if (found && found.FROM_DATE) {
          return false;
        }
        return true;
      }
      if (fieldName === 'TO_DATE' || fieldName === 'TO_TIME') {
        const found = this.lineErrorIds.find((item) => item.ID === rowItem.ID);
        if (found && found.TO_DATE) {
          return false;
        }
        return true;
      }
      return true;
    },
    sofIsNotValid(id, validationField) {
      const found = this.lineErrorIds.find((item) => item.ID === id);
      if (found && validationField) {
        if (found[validationField] && !found[validationField].result) {
          return true;
        }
        return false;
      }
      if (found) {
        return true;
      }
      return false;
    },
    validateSofWorklineDate(id) {
      if (this.lineType === 'workLines' && id) {
        const found = this.lines.find((item) => item.ID === id);
        if (found) {
          let from = this.combineFormattedDateAndTime(found.FROM_DATE, found.FROM_TIME); // is null if invalid date and/or time is provided.
          let to = this.combineFormattedDateAndTime(found.TO_DATE, found.TO_TIME); // is null if invalid date and/or time is provided.
          if (!from && !to) {
            return; // if either from or to is null.
          }
          to = new Date(to);
          from = new Date(from);

          if (from >= to) {
            const ERROR_MSG = "'From date' cannot be before 'to date'";
            let errorItem = this.lineErrorIds.find((item) => item.ID === id);
            if (errorItem) {
              errorItem.ERROR_MSG = ERROR_MSG;
              this.removeSofItemFromErrorList(id);
              this.lineErrorIds.push(errorItem);
            } else {
              errorItem = {
                ID: id,
                ERROR_MSG,
              };
              this.lineErrorIds.push(errorItem);
            }
          }
        }
      }
    },
    getErrorMsg(id, field, isErrorMsgProp) {
      const errorItem = this.lineErrorIds.find((item) => item.ID === id);
      if (errorItem) {
        if (field === 'FROM_DATE') {
          return errorItem.FROM_DATE.ERROR_MSG;
        }
        if (field === 'TO_DATE') {
          return errorItem.TO_DATE.ERROR_MSG;
        }
        if (field === 'VALIDATE_WORKLINE') {
          return errorItem.ERROR_MSG;
        }
      }
      if (isErrorMsgProp) {
        // check if its used for the errorMsg prop in gat-edit.
        return ''; // return empty string.
      }
      return false; // if its not errorMsg prop, return boolean.
    },
    createErrorMsg(result, lineType, prepend) {
      let ERROR_MSG = '';
      let diff = 0;
      if (result.ETPS) {
        diff = Math.round((Math.abs(result.ETPS_DIFF) + Number.EPSILON) * 10) / 10;
        ERROR_MSG = `${lineType + prepend} is ${diff}hours before ${result.ETPS.TYPE}`; // (ETPS date:'+GlobalHelperFunctions.globalFormatDateTime(result.ARRIVAL,'DD.MMM HH:ss')+')'
      }
      if (result.ARRIVAL) {
        // it's connected to the arrival of the vessel.
        diff = Math.round((Math.abs(result.ARRIVAL_DIFF) + Number.EPSILON) * 10) / 10;
        ERROR_MSG = `${lineType + prepend} is ${diff} hours before ${result.ARRIVAL.TYPE}`; // (Arrival date: '+GlobalHelperFunctions.globalFormatDateTime(result.ARRIVAL,'DD.MMM HH:ss')+')';
      }
      if (result.DEPARTURE) {
        diff = Math.round((Math.abs(result.DEPARTURE_DIFF) + Number.EPSILON) * 10) / 10;
        ERROR_MSG = `${lineType + prepend} is ${diff} hours after ${result.DEPARTURE.TYPE}`; // (Departure date: '+GlobalHelperFunctions.globalFormatDateTime(result.DEPARTURE,'DD.MMM HH:ss')+')';
      }
      return ERROR_MSG;
    },
    addSofItemToErrorList(id, result, lineType) {
      const found = this.lineErrorIds.find((item) => item.ID === id);
      const ERROR_MSG = '';
      if (result.FROM_DATE) {
        // eslint-disable-next-line no-param-reassign
        result.FROM_DATE.ERROR_MSG = this.createErrorMsg(
          result.FROM_DATE,
          lineType,
          lineType.toLowerCase() == 'workline' ? " 'from date' " : ''
        );
      }
      if (result.TO_DATE) {
        // eslint-disable-next-line no-param-reassign
        result.TO_DATE.ERROR_MSG = this.createErrorMsg(
          result.TO_DATE,
          lineType,
          lineType.toLowerCase() == 'workline' ? " 'To date' " : ''
        );
      }
      const errorItem = {
        ID: id,
        ERROR_MSG,
      };
      if (result.TO_DATE) {
        errorItem.TO_DATE = result.TO_DATE;
      }
      if (result.FROM_DATE) {
        errorItem.FROM_DATE = result.FROM_DATE;
      }
      errorItem.ID = id;
      if (found) {
        this.removeSofItemFromErrorList(id);
        this.lineErrorIds.push(errorItem);
      } else {
        this.lineErrorIds.push(errorItem);
      }
    },
    removeSofItemFromErrorList(id) {
      const idx = this.lineErrorIds.findIndex((item) => item.ID === id);
      if (idx >= 0) {
        this.lineErrorIds.splice(idx, 1);
      }
    },
    validateArrivalAndDeparture(lineType, rowData) {
      const result = {};
      const fromDate = rowData.FROM_DATE;
      const defaultTime = '12:00';
      const fromTime = rowData.FROM_TIME || defaultTime;
      const fromDateTime = `${fromDate}T${fromTime}`;
      if (lineType === 'statements' && fromDate) {
        // do nothing if fromDate doesnt exist
        // Validate statement dates.
        result.FROM_DATE = this.sofStatementIsValid(fromDateTime);
      }
      const toDate = rowData.TO_DATE;
      const toTime = rowData.TO_TIME || defaultTime;
      const toDateTime = `${toDate}T${toTime}`;
      if ((lineType === 'workLines' && fromDate) || toDate) {
        // Validate workLine dates.
        if (fromDate) {
          result.FROM_DATE = this.sofStatementIsValid(fromDateTime);
        }
        if (toDate) {
          result.TO_DATE = this.sofStatementIsValid(toDateTime);
        }
      }
      if (result.TO_DATE && result.TO_DATE.result == true) {
        delete result.TO_DATE;
      }
      if (result.FROM_DATE && result.FROM_DATE.result == true) {
        delete result.FROM_DATE;
      }
      if ((result.TO_DATE && !result.TO_DATE.result) || (result.FROM_DATE && !result.FROM_DATE.result)) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions, no-param-reassign
        lineType === 'workLines' ? (lineType = 'Workline') : (lineType = 'Statement');
        this.addSofItemToErrorList(rowData.ID, result, lineType);
      } else {
        this.removeSofItemFromErrorList(rowData.ID);
      }
      return result.result ? '' : 'Error';
    },
    sofStatementIsValid(sofDateValue) {
      // check if arrival and departure exists.
      const resultObj = {
        result: false,
      };
      // eslint-disable-next-line no-param-reassign
      sofDateValue = GlobalHelperFunctions.globalFormatDateTime(sofDateValue, 'YYYY-MM-DDTHH:mm');
      const arrival = this.arrival.DATE_TIME
        ? GlobalHelperFunctions.globalFormatDateTime(this.arrival.DATE_TIME, 'YYYY-MM-DDTHH:mm')
        : null;
      const departure = this.departure.DATE_TIME
        ? GlobalHelperFunctions.globalFormatDateTime(this.departure.DATE_TIME, 'YYYY-MM-DDTHH:mm')
        : null;

      const validETPSHours = -12; // set global vaue for pilot station here. (negative value)
      const validArrivalHours = -24; // set global value for arrival here. (negative value)
      const validDepartureHours = 6; // set global value for departure here. (positive value)

      let arrivalIsValid = false;
      if (arrival && this.arrival.TYPE === 'ETPS') {
        const etpsDiff = this.getTimeDifference(sofDateValue, arrival).hours;
        arrivalIsValid = etpsDiff >= validETPSHours;
        if (!arrivalIsValid) {
          resultObj.ETPS_DIFF = etpsDiff;
          resultObj.ETPS = this.arrival;
        }
      } else if (arrival) {
        const arrivalDiff = this.getTimeDifference(sofDateValue, arrival).hours;
        arrivalIsValid = arrivalDiff >= validArrivalHours;
        if (!arrivalIsValid) {
          resultObj.ARRIVAL_DIFF = arrivalDiff;
          resultObj.ARRIVAL = this.arrival;
        }
      }
      let departureIsValid = false;
      if (departure) {
        const depDiff = this.getTimeDifference(sofDateValue, departure).hours;
        departureIsValid = depDiff <= validDepartureHours;
        if (!departureIsValid) {
          resultObj.DEPARTURE_DIFF = depDiff;
          resultObj.DEPARTURE = this.departure;
        }
      }
      // Validate final result.
      if (arrival && departure) {
        resultObj.result = arrivalIsValid && departureIsValid;
      } else if (arrival) {
        resultObj.result = arrivalIsValid;
      } else if (departure) {
        resultObj.result = departureIsValid;
      }

      return resultObj;
    },
    getTimeDifference(date1, date2) {
      // eslint-disable-next-line no-param-reassign
      date1 = new Date(date1);
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions, no-param-reassign
      date2 ? (date2 = new Date(date2)) : (date2 = new Date());
      const seconds = date1.getTime() - date2.getTime();
      const minutes = seconds / 1000;
      const hours = seconds / (1000 * 3600);
      const days = seconds / (1000 * 3600 * 24);
      return { days, hours, minutes, seconds };
    },
    combineFormattedDateAndTime(date, time, isDateTime) {
      let result = null;
      if (date && time) {
        if (isDateTime) {
          // eslint-disable-next-line no-param-reassign
          date = date.slice(0, 10);
          // eslint-disable-next-line no-param-reassign
          time = time.slice(10);
          result = date + time;
        } else {
          result = `${date}T${time}`;
        }
      }
      return result;
    },
    hasPropertyValue(array, propertyName) {
      let hasProperty = false;
      array.forEach((item) => {
        // eslint-disable-next-line no-prototype-builtins
        if (item.hasOwnProperty(propertyName)) {
          hasProperty = true;
        }
      });
      /* console.log(hasProperty,propertyName) */
      return hasProperty;
    },
    deleteItem(id) {
      const lines = [...this.lines];
      const idx = lines.findIndex((line) => line.ID == id);
      if (idx >= 0) {
        lines.splice(idx, 1);
      }
      this.lines = lines;
      this.$emit('lines-deleted', lines);
    },
    /* deleteClicked(){ // Used for selectedItems (commented out due to new delete button for each line.)
             let selected = [...this.selectedKeys];
              let lines = [...this.lines];
              if(selected.length > 0){
                  for(let i = 0; selected.length > i; i++){
                      let idx = lines.findIndex(line => line.ID == selected[i]);
                      if(idx>=0){
                          lines.splice(idx, 1);
                      }
                  }
              }
              this.lines = lines;
              this.selectedKeys = [];
              this.$emit('lines-deleted',lines);
          }, */

    editChanged(lineType, rowData, editedField) {
      // inputValue
      if (this.lastItemIsEmptyObject(this.lines)) {
        this.lines.pop();
      }
      let prevDate = null;
      // set temp ID and init values for new items
      // eslint-disable-next-line array-callback-return
      this.lines.map((item) => {
        // eslint-disable-next-line no-prototype-builtins
        if (!item.hasOwnProperty('ID')) {
          // eslint-disable-next-line no-param-reassign
          item.ID = Math.floor(Math.random() * -1000000) - 1; // negativ = temporary
          // eslint-disable-next-line no-prototype-builtins
          if (!item.hasOwnProperty('SORT_ORDER') && this.sortAutomaticallyValue === 'Checked') {
            // eslint-disable-next-line no-param-reassign
            item.SORT_ORDER = this.getMaxSortOrder(this.lines) + 10;
          }
          // eslint-disable-next-line no-param-reassign
          item.SOF_ID = this.sofId;
          if (this.lineType == 'statements') {
            // eslint-disable-next-line no-param-reassign
            item.DETAIL_TYPE = 0;
          } else {
            // eslint-disable-next-line no-param-reassign
            item.DETAIL_TYPE = 1;
          }
        }
        if (!item.SORT_ORDER && this.sortAutomaticallyValue === 'Checked') {
          // eslint-disable-next-line no-param-reassign
          item.SORT_ORDER = this.getMaxSortOrder(this.lines) + 10;
        }
        // set the date if only time is given
        if (!item.FROM_DATE && item.FROM_TIME && prevDate) {
          // eslint-disable-next-line no-param-reassign
          item.FROM_DATE = prevDate;
        }
        if (item.FROM_DATE) {
          prevDate = item.FROM_DATE;
        }

        const fillFromDate = !(
          item.FROM_DATE ||
          editedField === 'FROM_DATE' ||
          (!item.TO_DATE && editedField === 'TO_DATE') ||
          (item.TO_DATE && editedField !== 'TO_DATE')
        );
        const fillToDate = !(
          item.TO_DATE ||
          editedField === 'TO_DATE' ||
          (!item.FROM_DATE && editedField === 'FROM_DATE') ||
          (item.FROM_DATE && editedField !== 'FROM_DATE')
        );
        if (this.lines.length == 1 && fillFromDate) {
          // eslint-disable-next-line no-param-reassign
          item.FROM_DATE = GlobalHelperFunctions.globalFormatDateTime(new Date(), 'YYYY-MM-DD');
        }
        if (this.lines.length == 1 && fillToDate) {
          if (new Date(item.FROM_DATE).getTime() < new Date().getTime()) {
            // eslint-disable-next-line no-param-reassign
            item.TO_DATE = GlobalHelperFunctions.globalFormatDateTime(new Date(), 'YYYY-MM-DD');
          } else {
            // eslint-disable-next-line no-param-reassign
            item.TO_DATE = item.FROM_DATE;
          }
        } else if (this.lines.length >= 2) {
          // Set date/time/quay only if previous line has data
          const prevToDate = this.lines[this.lines.length - 2].TO_DATE;
          const prevFromDate = this.lines[this.lines.length - 2].FROM_DATE;
          if (!this.lines[this.lines.length - 1].FROM_DATE && prevToDate) {
            this.lines[this.lines.length - 1].FROM_DATE = prevToDate;
          } else if (!this.lines[this.lines.length - 1].FROM_DATE && prevFromDate) {
            this.lines[this.lines.length - 1].FROM_DATE = prevFromDate;
          }

          if (!this.lines[this.lines.length - 1].TO_DATE && prevToDate) {
            this.lines[this.lines.length - 1].TO_DATE = prevToDate;
          }

          const prevToTime = this.lines[this.lines.length - 2].TO_TIME;
          if (!this.lines[this.lines.length - 1].FROM_TIME && prevToTime) {
            this.lines[this.lines.length - 1].FROM_TIME = prevToTime;
          }

          const prevQuay = this.lines[this.lines.length - 2].QUAY_ID;
          if (!this.lines[this.lines.length - 1].QUAY_ID && prevQuay) {
            this.lines[this.lines.length - 1].QUAY_ID = prevQuay;
          }
        }
      });
      if (lineType && rowData) {
        // only validate on certian fields.
        this.validateArrivalAndDeparture(lineType, rowData);
        this.validateSofWorklineDate(rowData.ID);
      }
      this.$emit('input', this.lines);
    },

    getMaxSortOrder(array) {
      let result = 0;
      // eslint-disable-next-line array-callback-return
      array.map((item) => {
        if (item.SORT_ORDER > result) {
          result = item.SORT_ORDER;
        }
      });
      return result;
    },

    lastItemIsEmptyObject(array) {
      let result = false;
      if (array && array.length > 0) {
        result = Object.entries(array[array.length - 1]).length === 0;
      }
      return result;
    },

    lookupCallStatus(code) {
      return GlobalHelperFunctions.lookupCode(code, this.constantsStore.portcallStatus);
    },

    getWorklineStatements(rowData) {
      if (this.helperStore.worklineStatements) {
        const result = this.helperStore.worklineStatements
          .filter((item) => {
            if (item.WORK_TYPE) {
              return item.WORK_TYPE == rowData.DETAIL_STATUS;
            }
            return true;
          })
          .map((item) => ({
            text: item.STATEMENT,
            value: item.STATEMENT,
          }));
        // If the workline user input is disabled but the workline has user-input-data it should be added to the items.
        if (this.applicationStore.globalSettings.SGL_WORKLINE_DISABLE_STATEMENT_USER_INPUT === 1 && rowData.STATEMENT) {
          const found = result.find((item) => item.value === rowData.STATEMENT);
          if (!found) {
            result.push({
              text: rowData.STATEMENT,
              value: rowData.STATEMENT,
            });
          }
        }
        return result;
      }
      return [];
    },

    onSelectionChanged(keys) {
      this.selectedKeys = keys;
    },

    getConstantTextByValue(constantArray, value) {
      let result = null;
      const constantItem = constantArray.find((item) => item.value == value); // Constant array must be defined in this component. For example berths
      if (constantItem && constantItem.text) {
        result = constantItem.text;
      }
      return result;
    },

    getWorklineStatus(value) {
      const worklineStatuses = [...this.worklineStatuses];
      return this.getConstantTextByValue(worklineStatuses, value);
    },
    getQuayById(id) {
      const quays = [...this.berths];
      return this.getConstantTextByValue(quays, id);
    },
  },
};
</script>

<style lang="scss" scoped>
/* OVERIDE CSS classes on SOF. (unlike any other page) */
.v-text-field fieldset,
.v-text-field .v-input__control {
  color: inherit;
  margin-top: 8px !important;
}
::v-deep .theme--light.v-text-field--filled > .v-input__control > .v-input__slot {
  background: white !important;
}
::v-deep .theme--dark.v-text-field--filled > .v-input__control > .v-input__slot {
  background: white !important;
}
::v-deep th {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
::v-deep tr {
  .pl-2 {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }
  .pl-1 {
    padding-left: 1px !important;
    padding-right: 1px !important;
  }
  /* background: #Fafafa !important; */
  td {
    padding: 1px 1px !important;
    padding-top: 1px !important;
    .v-text-field.v-text-field--enclosed .v-text-field__details {
      display: none;
    }
    .v-messages {
      display: none !important;
    }
    .v-input--checkbox {
      padding: 0;
      margin-top: -10px !important;
      margin-left: 16px !important;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

::v-deep
  .v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > td:not(.v-data-table__mobile-row),
.theme--light.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr:not(:last-child)
  > th:not(.v-data-table__mobile-row) {
  border-bottom: none !important;
}
.user-action {
  display: flex;
  flex-flow: row;
  width: 100%;
  height: 100%;
  margin-bottom: 4px;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.sof-compact-grid {
  th {
    padding: 5px 10px !important;
  }
  td {
    padding: 5px 10px !important;
  }
}
// red rgba(183, 28, 28)
// orange rgb(255, 152, 0)
.errorLine {
  margin-top: -6px;
  background-color: rgba(183, 28, 28);
  margin-bottom: 20px;
  width: calc(100% - 2px);
  margin-left: 1px;
  height: 2px;
  z-index: 1000 !important;
}
.sof-error-message {
  position: absolute;
  left: 0px;
  top: 2px;
  font-size: 12px;
  color: rgba(183, 28, 28); // Error color.
  height: 20px;
  width: 100vw;
}
// V-tooltip opacity
.v-tooltip__content.menuable__content__active {
  opacity: 1 !important;
}
</style>

<style>
.custom-placeholer-color input::placeholder {
  opacity: 1;
  color: rgba(0, 0, 0, 0.87) !important;
}
</style>
