<template>
  <gat-dialog v-model="showDialog" title="Email recipients" okButtonCaption="OK" @cancelClick="closeDialog"
    @okClick="okClicked" noPadding fullscreen v-resize="onWindowResize">
    <div class="d-flex pa-4" :class="isMobile ? 'flex-column' : ''">
      <div class="d-flex flex-column justify-end" :style="`width: ${containerWidth.connectedClients}`">
        <gat-grid :height="gridHeight" :items="sourceItems" :columns="connectedClientsColumns" title="Connected clients"
          :rowclass="getRowClass" fixedheader dense noFooter :selectable="!isMobile" :options="{
            checkboxIsHidden,
          }" disableSort keyName="ID" :selectedKeys="selectedSourceItemKeys" :rowMenu="sourceMenuItems"
          @selectionChanged="selectedSourceItemKeys = $event" @row-clicked="sourceItemClicked"
          @rowMenuClicked="sourceRowMenuClicked">
          <template v-slot:btns>
            <div v-if="!isMobile" class="d-flex pr-4 align-center" style="margin-bottom: 10px">
              <gat-edit noflex hide-details v-model="showContacts" label="Show contacts" checkbox :checkedValue="true"
                :uncheckedValue="false" />
            </div>
            <div v-else>
              <v-menu offset-y v-if="!isAllSelected">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-on="on" v-bind="attrs" class="mr-2" outlined small color="primary">Select all</v-btn>
                </template>
                <v-list>
                  <v-list-item @click="selectAllRecipients('TO')">TO</v-list-item>
                  <v-list-item @click="selectAllRecipients('CC')">CC</v-list-item>
                  <v-list-item @click="selectAllRecipients('BCC')">BCC</v-list-item>
                </v-list>
              </v-menu>
              <v-btn v-else-if="hasRecipients" class="mr-2" outlined small color="primary" @click="removeAll()">Unselect
                all</v-btn>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-on="on" v-bind="attrs" class="mr-2" outlined small color="primary">CC/BCC</v-btn>
                </template>
                <v-list>
                  <v-list-item @click="showCC = !showCC">{{ showCC ? 'Hide' : 'Show' }} CC</v-list-item>
                  <v-list-item @click="showBCC = !showBCC">{{ showBCC ? 'Hide' : 'Show' }} BCC</v-list-item>
                </v-list>
              </v-menu>
            </div>
          </template>
          <template slot="cell" slot-scope="props">
            <template v-if="props.column.field == `NAME`">
              <span v-if="props.row.TYP === itemTypes.contact">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <v-icon>{{ getIcon(props.row.TYP) }}</v-icon> <span> {{ props.value }}</span>
            </template>
            <template v-else-if="props.column.field == `to_input`">
              <div class="d-flex">
                <v-btn value="TO" class="mr-1" :outlined="!isSelectedRecipient(props.row, 'TO')" color="primary" small
                  @click="setRecipientsButtonValue(props.row, 'TO')">TO</v-btn>
                <v-btn v-show="showCC" color="primary" class="mr-1" value="CC"
                  :outlined="!isSelectedRecipient(props.row, 'CC')" small
                  @click="setRecipientsButtonValue(props.row, 'CC')">CC</v-btn>
                <v-btn v-show="showBCC" color="primary" value="BCC" :outlined="!isSelectedRecipient(props.row, 'BCC')"
                  small @click="setRecipientsButtonValue(props.row, 'BCC')">BCC</v-btn>
              </div>
              <!-- <v-radio-group row>
                <v-radio></v-radio>
                <v-radio></v-radio>
                <v-radio></v-radio>
              </v-radio-group> -->
            </template>
            <!-- <template v-if="props.column.field == `cc_input`">
              <v-radio></v-radio>
            </template>
            <template v-if="props.column.field == `bcc_input`">
              <v-radio></v-radio>
            </template> -->
          </template>
        </gat-grid>
      </div>
      <div v-if="!isMobile" class="px-4 d-flex justify-center" :class="isMobile ? '' : 'flex-column'"
        :style="`width: ${containerWidth.buttonsContainer}; padding-top:${isMobile ? '0px' : '116px'}`">
        <gat-button :caption="captions.to" :icon="icons.arrowRight" class="my-1" @click="add('TO')"
          :disabled="selectedSourceItemKeys.length === 0" />
        <gat-button :caption="captions.cc" :icon="icons.arrowRight" class="my-1" @click="add('CC')"
          :disabled="selectedSourceItemKeys.length === 0" />
        <gat-button :caption="captions.bcc" :icon="icons.arrowRight" class="my-1" @click="add('BCC')"
          :disabled="selectedSourceItemKeys.length === 0" />
        <gat-button :caption="captions.remove" :icon="icons.arrowLeft" class="my-1 mt-12" @click="removeSelected()"
          :disabled="selectedRecipientsItemKeys.length === 0" />
        <gat-button :caption="captions.removeAll" :icon="icons.arrowLeft" class="my-1" @click="removeAll()"
          :disabled="recipients.length === 0" />
      </div>
      <div class="d-flex flex-column" :style="`width: ${containerWidth.recipients}`" v-if="!isMobile">
        <EmailRecipientsManual @addManualClick="addManual" />
        <gat-grid :height="gridHeight" :items="recipients" :columns="recipientscolumns" dense noCellWrap noFooter
          noGroupHeaderToggle groupDesc title="Recipients" selectable keyName="ID" :rowMenu="recipientsMenuItems"
          :selectedKeys="selectedRecipientsItemKeys" @selectionChanged="selectedRecipientsItemKeys = $event"
          @row-clicked="recipientItemClicked" @rowMenuClicked="recipientsRowMenuClicked">
          <template v-slot:group.header="headerProps">
            <td :colspan="recipientscolumns.length">
              <div class="pl-2 text-subtitle-2">{{ headerProps.group }} ({{ headerProps.items.length }})</div>
            </td>
          </template>
          <template v-slot:btns>
            <div class="d-flex align-center pr-4" style="margin-bottom: 10px">
              <gat-edit hide-details v-model="includeCompanyName" label="Include company name" checkbox noflex
                :checkedValue="true" :uncheckedValue="false" />
            </div>
          </template>
          <template v-if="props.column.field == `icon`" slot="cell" slot-scope="props">
            <v-icon>{{ getIcon(props.row.TYP) }}</v-icon>
          </template>
        </gat-grid>
        <!-- <div v-if="!isMobile" class="d-flex justify-center">
          <gat-button
            :caption="captions.remove"
            :icon="icons.arrowLeft"
            class="my-1"
            @click="removeSelected()"
            :disabled="selectedRecipientsItemKeys.length === 0" />
          <gat-button
            :caption="captions.removeAll"
            :icon="icons.arrowLeft"
            class="my-1"
            @click="removeAll()"
            :disabled="recipients.length === 0" />
        </div> -->
      </div>
    </div>
  </gat-dialog>
</template>

<script>
import { isValidEmail } from '@/modules/email/emailFunctions';
import { defineComponent } from 'vue';
import { usePortcallStore } from '@/store/portcallStore';
import { apiGet } from '../../store/api';
import EmailRecipientsManual from './EmailRecipientsManual.vue';
import SednaApiService from '../../services/api/external/sednaAPI';

export default defineComponent({
  name: 'EmailRecipientsDialog',
  props: {
    value: {
      type: Boolean,
    },
    /** 'portcall' || 'client' || 'vessel'  */
    location: String,
    portcallId: {
      type: [Number, String],
      required: true,
    },
    vesselId: {
      type: [Number, String],
      required: false,
    },
    clientId: {
      type: [Number, String],
      required: false,
    },
    /** If the component should redirect to new route on OK-click. Is false when used with email editor. */
    redirect: {
      type: Boolean,
      default: true,
    },
    to: Array,
    bcc: Array,
    cc: Array,
    routeParams: {
      type: Object,
      default: undefined,
    },
  },
  components: {
    EmailRecipientsManual,
  },
  setup() {
    const portcallStore = usePortcallStore();
    return {
      portcallStore,
    };
  },
  data() {
    return {
      showDialog: false,
      selectedSourceItemKeys: [],
      selectedRecipientsItemKeys: [],
      recipients: [],
      sourceItemsRaw: [],
      includeCompanyName: false,
      showContacts: true,
      icons: {
        vessel: 'mdi-ferry',
        client: 'mdi-account-multiple',
        contact: 'mdi-account',
        arrowRight: 'mdi-arrow-right-bold',
        arrowLeft: 'mdi-arrow-left-bold',
      },
      itemTypes: {
        vessel: 2,
        client: 1,
        contact: 3,
      },
      menuItemNames: {
        to: 'to',
        cc: 'cc',
        bcc: 'bcc',
        remove: 'remove',
        removeAll: 'removeAll',
      },
      captions: {
        to: 'TO',
        cc: 'CC',
        bcc: 'BCC',
        remove: 'Remove',
        removeAll: 'Remove all',
      },
      windowSize: {
        x: 0,
        y: 0,
      },
      showCC: false,
      showBCC: false,
    };
  },

  created() {
    this.getDefaultValues();
    this.getConnectedClients();
  },

  watch: {
    value: {
      immediate: true,
      handler(val) {
        if (this.showDialog !== val) {
          this.showDialog = val;
        }
        if (this.showDialog) {
          this.removeAll();
          this.addRecipientsFromProps(this.to, 'TO');
          this.addRecipientsFromProps(this.cc, 'CC');
          this.addRecipientsFromProps(this.bcc, 'BCC');
        }
      },
    },
    showDialog(val) {
      this.$emit('input', val);
    },
  },

  computed: {
    hasRecipients() {
      return this.recipients && Array.isArray(this.recipients) && this.recipients.length > 0;
    },
    isAllSelected() {
      if (Array.isArray(this.recipients) && Array.isArray(this.sourceItems) && this.recipients && this.sourceItems) {
        return this.recipients.length === this.sourceItems.length;
      }
      return false;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    containerWidth() {
      // Set the width of each container here.
      const result = {
        connectedClients: '50%',
        buttonsContainer: '10%',
        recipients: '40%',
      };
      if (this.$vuetify.breakpoint.lgAndDown) {
        result.connectedClients = '50%';
        result.buttonsContainer = '10%';
        result.recipients = '40%';
      }
      if (this.$vuetify.breakpoint.mdAndDown) {
        result.connectedClients = '50%';
        result.buttonsContainer = '15%';
        result.recipients = '35%';
      }
      if (this.$vuetify.breakpoint.smAndDown) {
        result.connectedClients = '100%';
        result.buttonsContainer = '100%';
        result.recipients = '100%';
      }
      return result;
    },
    gridHeight() {
      let result = 'calc(100vh - 280px)';
      if (this.$vuetify.breakpoint.smAndDown) {
        result = 'calc(100vh - 160px)';
      }
      if (this.windowSize.y < 700) {
        // The min-height of the grid.
        result = '400px';
      }
      return result;
    },
    connectedClientsColumns() {
      const result = [];
      if (this.isMobile) {
        let header = 'TO';
        if (this.showCC) {
          header += ' / CC';
        }
        if (this.showBCC) {
          header += ' / BCC';
        }
        result.push({ header, field: 'to_input' });
      }
      result.push({ header: 'Name', field: 'NAME', nowrap: true });
      result.push({ field: 'TYPE_NAME', nowrap: true });
      result.push({ header: 'Email', field: 'EMAIL', nowrap: true });
      return result;
    },
    recipientscolumns() {
      const result = [];
      result.push({ field: 'icon', hindeOnPhone: true, nowrap: true });
      result.push({
        header: 'Name',
        calculated: (rowData) => this.getRecipientName(rowData),
        nowrap: true,
      });
      result.push({ header: 'Email', field: 'EMAIL', nowrap: true });
      result.push({ field: 'category', groupedBy: true });

      return result;
    },
    sourceItems() {
      if (this.isMobile) {
        return this.sourceItemsRaw;
      }
      const { hotlist } = this.portcallStore;
      return this.sourceItemsRaw
        .filter((item) => {
          const hotlistRecipients = hotlist.find((hotlistItem) => hotlistItem.CLIENT_ID === item.CLIENT_ID)?.RECIPIENTS;
          if (
            Array.isArray(hotlistRecipients) &&
            hotlistRecipients.length > 0 &&
            !hotlistRecipients.includes(item.ID) &&
            item.CLIENT_ID !== item.ID
          ) {
            return false;
          }
          if (!this.showContacts) {
            if (item.TYP === this.itemTypes.contact) {
              return false;
            }
          }

          if (this.recipients.some((i) => i.EMAIL === item.EMAIL) && item.TYP !== this.itemTypes.client) {
            return false;
          }

          if (!this.recipients.includes(item)) {
            return true;
          }
          if (item.TYP === this.itemTypes.client && this.showContacts) {
            return !this.allContactsForClientAdded(item.ID);
          }
          return false;
        })
        .map((item) => {
          const result = item;
          const hotlistRecipients = hotlist.find((hotlistItem) => hotlistItem.CLIENT_ID === item.CLIENT_ID)?.RECIPIENTS;
          const isRecipient = () => {
            if (Array.isArray(hotlistRecipients) && hotlistRecipients.length > 0) {
              return hotlist
                .find((hotlistItem) => hotlistItem.CLIENT_ID === item.CLIENT_ID)
                ?.RECIPIENTS.includes(item.ID);
            }
            return true;
          };
          // console.log(result, result.NAME, hotlistRecipients, isRecipient());
          result.EMAIL = isRecipient() ? result.EMAIL : '';
          result.IS_RECIPIENT = isRecipient();
          return result;
        });
    },
    recipientsMenuItems() {
      const menuItems = [];
      menuItems.push({
        name: this.menuItemNames.remove,
        caption: this.captions.remove,
        icon: this.icons.arrowLeft,
      });
      menuItems.push({
        name: this.menuItemNames.removeAll,
        caption: this.captions.removeAll,
        icon: this.icons.arrowLeft,
      });
      return menuItems;
    },
    sourceMenuItems() {
      const menuItems = [];
      menuItems.push({
        name: this.menuItemNames.to,
        caption: this.captions.to,
        icon: this.icons.arrowRight,
      });
      menuItems.push({
        name: this.menuItemNames.cc,
        caption: this.captions.cc,
        icon: this.icons.arrowRight,
      });
      menuItems.push({
        name: this.menuItemNames.bcc,
        caption: this.captions.bcc,
        icon: this.icons.arrowRight,
      });
      return menuItems;
    },
    mainContainerStyles() {
      const styles = 'display: flex;';
      if (this.$vuetify.breakpoint.mdAndDown) {
        return `${styles} flex-direction: column;`;
      }
      if (this.$vuetify.breakpoint.lgOnly) {
        return `${styles} flex-direction: row; align-items: center;`;
      }
      return `${styles} flex-direction: row; align-items: center; justify-content: space-evenly`;
    },
  },

  methods: {
    checkboxIsHidden(item) {
      if (item.IS_RECIPIENT) {
        return false;
      }
      return true;
    },
    selectAllRecipients(action) {
      if (action === 'CC' && !this.showCC) {
        this.showCC = true;
      }
      if (action === 'BCC' && !this.showBCC) {
        this.showBCC = true;
      }
      this.recipients = [];
      this.sourceItems.forEach((item) => {
        this.setRecipientsButtonValue(item, action);
      });
    },

    removeAllSelected() {
      this.removeAll();
    },

    getRecipientsButtonValue(/* item */) {
      return 0;
    },
    isSelectedRecipient(item, action) {
      const found = this.recipients.find((recipient) => recipient.ID === item.ID && recipient.category === action);
      if (found) {
        return true;
      }
      return false;
    },
    setRecipientsButtonValue(item, action) {
      const removeRecipient = this.recipients.find(
        (recipient) => recipient.ID === item.ID && recipient.category === action
      );
      if (removeRecipient) {
        this.removeItemById(item.ID);
      } else {
        this.addAsRecipient(item.ID, action);
      }
    },
    onWindowResize() {
      this.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },
    addRecipientsFromProps(recipitentsArr, category) {
      const recipients = [];
      if (Array.isArray(recipitentsArr)) {
        recipitentsArr.forEach((item) => {
          if (typeof item === 'string' && isValidEmail(item)) {
            recipients.push({
              EMAIL: item,
              CLIENT_NAME: item,
              NAME: item,
            });
          } else {
            recipients.push(item);
          }
        });
      }
      const filteredRecipients = this.recipients.filter((item) => item.category !== category);
      this.recipients = filteredRecipients;
      if (Array.isArray(recipients) && recipients.length > 0) {
        const withCategory = [...recipients].map((item) => ({
          ...item,
          category,
          ID: `${item.CLIENT_NAME}${item.NAME}`,
        }));
        this.addManual(withCategory);
      }
    },
    closeDialog() {
      this.showDialog = false;
    },
    async getDefaultValues() {
      const value = await apiGet('portcall/emailRecipients/defaultValues');
      this.includeCompanyName = !!value;
    },
    async getConnectedClients() {
      const response = await apiGet(`portcall/emailRecipients/connectedClients/${this.portcallId}`);
      this.sourceItemsRaw = [...response];
    },
    add(slot) {
      this.selectedSourceItemKeys.forEach((itemId) => {
        this.addAsRecipient(itemId, slot);
      });
      this.selectedSourceItemKeys = [];
    },
    removeAll() {
      this.recipients = [];
    },
    removeSelected() {
      this.selectedRecipientsItemKeys.forEach((itemId) => {
        this.removeItemById(itemId);
      });
    },
    removeItemById(itemId) {
      const idx = this.recipients.findIndex((item) => item.ID === itemId);
      if (idx > -1) {
        this.recipients.splice(idx, 1);
      }
    },
    addAsRecipient(itemId, slot) {
      const item = this.sourceItems.find((sourceItem) => sourceItem.ID === itemId);
      if (item) {
        item.category = slot;
        if (!this.recipients.includes(item)) {
          this.recipients.push(item);
        }
      }
    },
    removeFromSourceList(itemId) {
      const idx = this.sourceItems.findIndex((item) => item.ID === itemId);
      if (idx > -1) {
        this.sourceItems.splice(idx, 1);
      }
    },
    sourceItemClicked(rowItem) {
      if (rowItem.IS_RECIPIENT) {
        const idx = this.selectedSourceItemKeys.findIndex((item) => item === rowItem.ID);
        if (idx > -1) {
          this.selectedSourceItemKeys.splice(idx, 1);
        } else {
          this.selectedSourceItemKeys.push(rowItem.ID);
        }
      }
    },
    recipientItemClicked(rowItem) {
      const idx = this.selectedRecipientsItemKeys.findIndex((item) => item === rowItem.ID);
      if (idx > -1) {
        this.selectedRecipientsItemKeys.splice(idx, 1);
      } else {
        this.selectedRecipientsItemKeys.push(rowItem.ID);
      }
    },
    getRecipientName(rowData) {
      if (this.includeCompanyName && rowData.TYP !== this.itemTypes.client && rowData.TYP !== this.itemTypes.vessel) {
        if (rowData.EMAIL === rowData.NAME && rowData.EMAIL === rowData.CLIENT_NAME) {
          // If name, email and client name is the same, return the email.
          return rowData.EMAIL;
        }
        if (rowData.NAME && rowData.NAME.length > 0 && rowData.CLIENT_NAME && rowData.CLIENT_NAME.length > 0) {
          if (!rowData.CLIENT_NAME) {
            return rowData.NAME;
          }
          if (!rowData.NAME) {
            return rowData.CLIENT_NAME;
          }

          return `${rowData.NAME} from ${rowData.CLIENT_NAME}`;
        }
      }
      if (rowData.NAME && rowData.NAME.length > 0) {
        return rowData.NAME;
      }
      return '';
    },
    getIcon(type) {
      switch (type) {
        case this.itemTypes.client:
          return this.icons.client;
        case this.itemTypes.vessel:
          return this.icons.vessel;
        case this.itemTypes.contact:
          return this.icons.contact;
        default:
          return this.icons.client;
      }
    },
    getRowClass(rowItem) {
      return this.recipients.includes(rowItem) ? 'ignored' : '';
    },
    allContactsForClientAdded(clientID) {
      const amountClientsRaw = this.sourceItemsRaw.filter(
        (item) => item.CLIENT_ID === clientID && item.ID !== clientID
      ).length;
      const amountClientsAdded = this.recipients.filter(
        (item) => item.CLIENT_ID === clientID && item.ID !== clientID
      ).length;
      return amountClientsRaw === amountClientsAdded;
    },
    addManual($event) {
      $event.forEach((item) => {
        if (!this.recipients.some((i) => i.EMAIL === item.EMAIL)) {
          this.recipients.push(item);
        }
      });
    },
    recipientsRowMenuClicked($event) {
      // eslint-disable-next-line default-case
      switch ($event.menuItem.name) {
        case this.menuItemNames.removeAll:
          this.removeAll();
          break;
        case this.menuItemNames.remove:
          this.removeItemById($event.rowItem.ID);
          break;
      }
    },
    sourceRowMenuClicked($event) {
      // eslint-disable-next-line default-case
      switch ($event.menuItem.name) {
        case this.menuItemNames.to:
          this.addAsRecipient($event.rowItem.ID, 'TO');
          break;
        case this.menuItemNames.cc:
          this.addAsRecipient($event.rowItem.ID, 'CC');
          break;
        case this.menuItemNames.bcc:
          this.addAsRecipient($event.rowItem.ID, 'BCC');
          break;
      }
    },
    okClicked() {
      // TODO format recipients. ATM its full items with attached category(to/cc/bb)
      // Must check if includeCompanyName is set when building the name-string
      const to = this.recipients
        .filter((item) => item.category.toUpperCase() === 'TO')
        .map((item) => ({ NAME: item.NAME, EMAIL: item.EMAIL, CLIENT_NAME: item.CLIENT_NAME, ID: item.ID }));
      const bcc = this.recipients
        .filter((item) => item.category.toUpperCase() === 'BCC')
        .map((item) => ({ NAME: item.NAME, EMAIL: item.EMAIL, CLIENT_NAME: item.CLIENT_NAME, ID: item.ID }));
      const cc = this.recipients
        .filter((item) => item.category.toUpperCase() === 'CC')
        .map((item) => ({ NAME: item.NAME, EMAIL: item.EMAIL, CLIENT_NAME: item.CLIENT_NAME, ID: item.ID }));
      const sednaApi = new SednaApiService(this.portcallStore.callDetails.SETUP_ID);
      if (this.redirect && !sednaApi.isSednaIntegration) {
        apiGet('mail/new-email').then((result) => {
          if (result && result.payload) {
            const emailId = result.payload.ID;
            const mailNo = result.payload.MAIL_NO;
            if (this.location === 'portcall') {
              this.$router.push({
                name: 'prortcallEmailNew',
                params: { callId: this.portcallId, location: 'portcall', emailId, to, bcc, cc, mailNo },
              });
            }
          }
        });
      } else {
        // on same route. Emit selected values.
        this.$emit('recipients-selected', { to, bcc, cc });
      }
    },
  },
});
</script>

<style scoped>
.recipients-container-btns {
  display: flex;
  margin: 5px 20px 20px 20px;
  justify-content: center;
}

#source-container {
  display: flex;
  justify-content: flex-end;
}
</style>
