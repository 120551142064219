/* eslint-disable no-await-in-loop */
/* eslint-disable class-methods-use-this */

import { apiGet, apiPost } from "@/store/api"
import { useApplicationStore } from "@/store/applicationStore"

interface IDraftEmailFromTemplate {
    mailNo: number,
    emailId: number,
    subject: string,
    content: string,
    createdBy: string,
    location?: string,
    to: { EMAIL: string, NAME: string }[],
    cc: { EMAIL: string, NAME: string }[],
    bcc: { EMAIL: string, NAME: string }[],
}

export default class SednaApiService {
    applicationStore = useApplicationStore()

    isSednaIntegration = false

    agencySetting

    sednaHeaders

    constructor(agencyId: number) {
        this.agencySetting = this.applicationStore.agencySettings.find(agency => agency.ID === agencyId)
        this.isSednaIntegration = (this.agencySetting?.EMAIL_TYPE ?? 0) === 7

        const { SEDNA_API_USR, SEDNA_API_PWD } = this.agencySetting;
        const basicAuthBase64 = btoa(`${SEDNA_API_USR}:${SEDNA_API_PWD}`)
        this.sednaHeaders = { accept: 'application/json', authorization: `Basic ${basicAuthBase64}`, 'content-Type': 'application/vnd.api+json' };
    }

    fileToBase64(file: File) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(file);
        })
    }

    controller(location: string) {
        return location === 'client' ? 'clients' : location
    }

    async getDocmentDetail(documentId: number) {
        const { SEDNA_URL } = this.agencySetting;
        const responseJson = await apiPost(`mail/sedna/read`, {
            SEDNA_URL: `${SEDNA_URL}/document/${documentId}`, AUTHORIZATION: this.sednaHeaders.authorization
        })
        return responseJson
    }

    async getAllSednaEmail(sednaJobId: string) {
        const { SEDNA_URL } = this.agencySetting;
        let hasMoreEmails = true
        let linkToFetch = `${SEDNA_URL}/job-reference/${`${sednaJobId}`.replace('MIX:', '')}/message?fields[message]=messageId,subject,hasAttachment,receivedAt,sentAt,from,to,cc,bcc,bodyHtml`
        const getMessagesResponseJsons = []
        while (hasMoreEmails) {
            const responseJson = await apiPost(`mail/sedna/read`, {
                SEDNA_URL: linkToFetch, AUTHORIZATION: this.sednaHeaders.authorization
            })
            if (responseJson?.data?.length > 0) {
                linkToFetch = responseJson.links.next
                getMessagesResponseJsons.push(responseJson);
            } else {
                hasMoreEmails = false;
            }
        }

        return getMessagesResponseJsons
    }

    async getEmailById(mailId: number) {
        const { SEDNA_URL } = this.agencySetting;
        const responseJson = await apiPost(`mail/sedna/read`, {
            SEDNA_URL: `${SEDNA_URL}/message/${mailId}?fields[message]=messageId,subject,hasAttachment,receivedAt,sentAt,from,to,cc,bcc,bodyHtml`, AUTHORIZATION: this.sednaHeaders.authorization
        })
        return responseJson;
    }

    async getSednaJobId(locationId: number, location: string) {
        const sednaJobId = await apiGet(`${this.controller(location)}/sednajobid/${locationId}`)
        return sednaJobId;
    }

    async createSednaJobId(gsResponse: any) {
        const { SEDNA_URL } = this.agencySetting;
        const responseJson = await apiPost(`mail/sedna/write`, {
            SEDNA_URL: `${SEDNA_URL}/job-reference`, AUTHORIZATION: this.sednaHeaders.authorization, PAYLOAD: JSON.stringify(gsResponse)
        })
        return responseJson
    }

    async updateSednaJobId(locationId: number, sednaJobId: string, location: string) {
        const { SEDNA_URL } = this.agencySetting;
        const gsResponse = await apiGet(`${this.controller(location)}/sednajobref/${locationId}`)

        // update job id in sedna
        const success = await apiPost(`mail/sedna/patch`, {
            SEDNA_URL: `${SEDNA_URL}/job-reference/${`${sednaJobId}`.replace('MIX:', '')}`, AUTHORIZATION: this.sednaHeaders.authorization, PAYLOAD: JSON.stringify(gsResponse)
        })

        return success
    }

    async createDraftMessage(sednaDraftMessage: any) {
        const { SEDNA_URL } = this.agencySetting;
        const responseJson = await apiPost(`mail/sedna/write`, {
            SEDNA_URL: `${SEDNA_URL}/message`, AUTHORIZATION: this.sednaHeaders.authorization, PAYLOAD: JSON.stringify(sednaDraftMessage)
        })
        const mailId = responseJson?.data?.id;
        return mailId
    }

    async updateDraftMessageWithJobId(sednaJobId: string, mailId: number) {
        const { SEDNA_URL } = this.agencySetting;

        // update the draft with sedna job id
        const success = await apiPost(`mail/sedna/update`, {
            SEDNA_URL: `${SEDNA_URL}/message/${mailId}/relationships/job-reference`, AUTHORIZATION: this.sednaHeaders.authorization, PAYLOAD: JSON.stringify({ data: [{ id: `${sednaJobId}`.replace('MIX:', '') }] })
        })
        return success
    }

    async uploadAttachments(mailId: number, attachments: File[]) {
        const { SEDNA_URL } = this.agencySetting;
        attachments.forEach(async (file) => {
            const fileAsBase64 = await this.fileToBase64(file)
            const attachment = { base64Content: fileAsBase64, fileName: file.name, fileSize: file.size }
            await apiPost(`mail/sedna/update`, {
                SEDNA_URL: `${SEDNA_URL}/message/${mailId}/document`, AUTHORIZATION: this.sednaHeaders.authorization, PAYLOAD: JSON.stringify({ data: attachment })
            })
        })
    }

    createComposeEditSednaEmailUrl(mailId: number) {
        const { SEDNA_URL, SEDNA_TEAM_ID } = this.agencySetting;
        const urlParts = SEDNA_URL.split('.com')
        const teamPart = SEDNA_TEAM_ID ? `?teamId=${SEDNA_TEAM_ID}` : ''
        const externalSednaUrl = `${urlParts[0]}.com/compose/message/${mailId ?? 'new'}${teamPart}`;
        return externalSednaUrl;
    }

    createOpenSednaEmailUrl(mailId: number) {
        const { SEDNA_URL, SEDNA_TEAM_ID } = this.agencySetting;
        const urlParts = SEDNA_URL.split('.com')
        const teamPart = SEDNA_TEAM_ID ? `?teamId=${SEDNA_TEAM_ID}` : ''
        const externalSednaUrl = `${urlParts[0]}.com/list/message/${mailId}${teamPart}`;
        return externalSednaUrl;
    }

    createReplySednaEmailUrl(mailId: number, replyType: null | 'all' | 'forward' | 'open') {
        if (replyType === 'open') {
            return this.createOpenSednaEmailUrl(mailId)
        }
        const { SEDNA_URL, SEDNA_TEAM_ID } = this.agencySetting;
        const urlParts = SEDNA_URL.split('.com')
        const teamPart = SEDNA_TEAM_ID ? `?teamId=${SEDNA_TEAM_ID}` : ''
        const firstPart = `${urlParts[0]}.com/follow-up/message/${mailId}/`;
        // eslint-disable-next-line no-nested-ternary
        const middlePart = replyType === 'all' ? 'replyAll' : replyType === 'forward' ? 'forward' : 'reply';
        const externalSednaUrl = `${firstPart}${middlePart}${teamPart}`;
        return externalSednaUrl
    }

    async openSednaUrlForJobTag(sednaJobId: string, allTeams: boolean, mailboxType: 'all' | 'draft' | 'inbox' | 'myWork' | 'following' | 'sent' = 'all') {
        const { SEDNA_URL, SEDNA_TEAM_ID } = this.agencySetting;
        const urlParts = SEDNA_URL.split('.com')

        const mailBoxToFilter = `&filter=${mailboxType}`;
        const teamFilter = SEDNA_TEAM_ID ? `&teamId=${SEDNA_TEAM_ID}` : ''
        const teamsFilter = allTeams ? '&teams=all' : '';
        const responseJson = await apiPost(`mail/sedna/read`, {
            SEDNA_URL: `${SEDNA_URL}/job-reference/${`${sednaJobId}`.replace('MIX:', '')}`, AUTHORIZATION: this.sednaHeaders.authorization
        })
        if (responseJson?.data?.attributes) {
            const urlParams = { "type": "and", "children": [{ "term": { "type": "jobReference", "query": responseJson.data.attributes.name, "_data": { "name": responseJson.data.attributes.name, "tagId": responseJson.data.attributes.tagId } } }] }
            const firstPart = `${urlParts[0]}.com/list?filterSet=`;
            const middlePart = encodeURI(JSON.stringify(urlParams));
            const lastPart = `${mailBoxToFilter}${teamFilter}${teamsFilter}`;
            const externalSednaUrl = `${firstPart}${middlePart}${lastPart}`;
            return externalSednaUrl
        }
        return null;
    }

    async createSednaJobRef(locationId: number, location: string) {
        // create job reference in gs-backend
        const gsGetResponse = await apiGet(`${this.controller(location)}/sednajobref/${locationId}`);

        // create job id in sedna
        const sednaPostResponseJson = await this.createSednaJobId(gsGetResponse);

        if (sednaPostResponseJson) {
            // update portcall/vessel/client with sedna job id
            const gsPostResponse = await apiPost(`${this.controller(location)}/sednajobref`, { ID: locationId, SEDNA_JOB_ID: sednaPostResponseJson.data?.id });

            if (gsPostResponse.payload) {
                return gsPostResponse.payload
            }
        }
        return null;
    }

    splitMultipleReceipients(email: string, name: string) {
        const emails = email.replace(/\s/g, '').split(/[,;]/g)
        if (emails.length > 1) {
            return emails.map(eml => ({ email: eml }))
        }
        return emails.map(eml => ({ email: eml, name }))
    }

    async composeNewSednaEmail(sednaJobId: string, attachments: File[], draftMessage?: IDraftEmailFromTemplate) {
        const { SEDNA_TEAM_ID } = this.agencySetting;

        const sednaDraftMessage = {
            data: {
                type: 'string',
                attributes: {
                    body: draftMessage?.content ?? '',
                    fromUser: this.applicationStore.user.userRights['U.EMAIL'],
                    fromTeam: SEDNA_TEAM_ID ?? '',
                    subject: draftMessage?.subject ?? '',
                    to: draftMessage?.to?.map(t => this.splitMultipleReceipients(t.EMAIL, t.NAME))?.flat() ?? [],
                    cc: draftMessage?.cc?.map(t => this.splitMultipleReceipients(t.EMAIL, t.NAME))?.flat() ?? [],
                    bcc: draftMessage?.bcc?.map(t => this.splitMultipleReceipients(t.EMAIL, t.NAME))?.flat() ?? [],
                }
            }
        }

        // create a draft message
        const mailId = await this.createDraftMessage(sednaDraftMessage);

        if (mailId) {
            // update the draft with sedna job id
            const updateStatus = await this.updateDraftMessageWithJobId(sednaJobId, mailId);
            if (!updateStatus) {
                return
            }

            // attach the documents to send
            if (attachments.length > 0) {
                await this.uploadAttachments(mailId, attachments);
            }

            // open sedna web editor or gatship web email editor
            const externalSednaUrl = this.createComposeEditSednaEmailUrl(mailId);
            // eslint-disable-next-line consistent-return
            return externalSednaUrl
        }
    }
}
